<template>
  <a-card :bordered="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:6}" :wrapper-col="{xs:18}">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="大区">
              <a-input v-model="searchData.name" placeholder="请输入大区名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
              <a-button type="primary" @click="query()" icon="search">查询</a-button>
              <a-button type="default" @click=" reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="isCheck" type="primary" @click="toHandler" icon="copy">查看</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table :loading="tableLoading" :rowKey="record => record.id"
        :data-source="tableData" bordered :pagination="page"
        @change="changeTable" :customRow="changeTableRow" :row-selection="rowSelection">
        <a-table-column title="大区" data-index="name" align="center"></a-table-column>
        <a-table-column title="菱感担当" data-index="" align="center">
          <template slot-scope="text, row">
            <a-tag v-for="(item, index) in row.managerVOS" :key="index">{{item.name}}</a-tag>
          </template>
        </a-table-column>
        <a-table-column title="大区余额" data-index="areaTotalAmount" align="center">
          <template slot-scope="text"> {{ text | formatMoney }} </template>
        </a-table-column>
      </a-table>
    </div>
    <AreaBalanceDetailed ref="AreaBalanceDetailed"></AreaBalanceDetailed>
  </a-card>
</template>

<script>
import AreaBalanceDetailed from "./AreaBalanceDetailed.vue"
import {checkPermission} from "@/utils/permissions";

export default {
  name: "AreaBalance",

  components: {
    AreaBalanceDetailed
  },
  data() {
    return {
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: {y: 600},
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      isCheck: checkPermission('area:balance:check'),
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
    }
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        }
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取数据
    getList() {
      this.tableLoading = true
      let obj = {
        pageSize: this.page.pageSize,
        pageNumber: this.page.current,
        ...this.searchData
      }
      this.axios.get('/api/firm/mhiac/mhiacArea/areaAssets', {params: obj}).then(res => {
        if (res.code === 200) {
          const {total, records} = res.body
          this.page.total = total
          this.tableData = records
        }
      }).finally(() => this.tableLoading = false)
    },
    // 查询数据
    query(){
      this.page.current = 1;
      this.page.pageSize = 10;
      this.getList();
    },

    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList()
    },
    toHandler() {
      this.$refs.AreaBalanceDetailed.setRowData(this.selectedRows[0])
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {};
      this.page.current = 1;
      this.page.pageSize = 10;
      this.page = Object.assign({}, this.page)
      this.getList()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: e => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>